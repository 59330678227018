var config = {};
config.env = 'uat';

//AWS
config.aws = {};
//config.aws.apiRoot = "https://3d7qlpg9pd-vpce-0d5921de9d274d2eb.execute-api.us-west-2.amazonaws.com/dev/";
// config.aws.apiRoot =  "https://j66cqr4a1j.execute-api.us-west-2.amazonaws.com/uat/";
config.aws.apiRoot = "https://uat-api-mice.amgen.com/";

//SSO
config.sso = {};
config.sso.url = "https://dev-630954.okta.com/home/selfdev630954_demookta_2/0oa1ihwzcwpC2mKfu357/aln1iiaocr7jdzWe1357";

//api-key
config.api = {};
config.api.key = 'VGbjjodDG6iun5FuVbMv5mxVkudpQy153P3EVcE0';

export default config;
